<template>
  <b-modal
    ref="ticket-modal"
    :title="title"
    no-close-on-backdrop
    hide-footer
    @show="loadTicket"
    size="xl"
    dialog-class="ticket-modal position-relative"
    body-class="p-0"
    header-class="align-items-center"
  >
    <template #modal-header="{ close }">
      <div class="d-flex">
        <h5 class="mb-0">{{title}}</h5>
        <TicketStatus class="ml-2" :status="status" />
      </div>
      <b-button size="sm" variant="outline-danger" @click="close()">
        <i class="fas fa-times"></i>
      </b-button>
    </template>
    <div class="ticket-table">

      <table v-if="!isLoading" class="table-item table table-sm table-bordered mb-0 table-hover">
        <thead class="bg-info">
          <tr>
            <th width="20%">ประเภท</th>
            <th width="10%">หมายเลข</th>
            <th width="10%">ยอดเดิมพัน</th>
            <th width="10%">ส่วนลด</th>
            <th width="10%">รวม</th>
            <th width="10%">จ่าย</th>
            <th width="15%">ถูกรางวัล</th>
            <th width="15%">สถานะ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item._id" :class="[{'alert-success':item.isWon}]">
            <td class="text-center"><NumberType :type="item.type" /></td>
            <td class="text-center text-primary">{{item.number}}</td>
            <td class="text-right">{{item.amount | amountFormat(2)}}</td>
            <td class="text-right">{{item.discount | amountFormat(2)}}</td>
            <td class="text-right">{{item.credit | amountFormat(2)}}</td>
            <td class="text-right">{{item.pay | amountFormat(2)}}</td>
            <td>
              <div v-if="item.isWon===true" class="text-success text-right">{{item.reward | amountFormat(2)}}</div>
              <div v-else-if="item.isWon===false" class="text-danger text-center">ไม่ถูกรางวัล</div>
            </td>
            <td class="text-center"><NumberStatus :status="item.status" /></td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th class="text-center">รวม</th>
            <th class="text-center">{{summary.length}} รายการ</th>
            <th class="text-right">{{summary.amount | amountFormat(2)}}</th>
            <th class="text-right">{{summary.discount | amountFormat(2)}}</th>
            <th class="text-right">{{summary.credit | amountFormat(2)}}</th>
            <th></th>
            <th class="text-right text-success">{{summary.won | amountFormat(2)}}</th>
            <th></th>
          </tr>
        </tfoot>
      </table>

      <loading
        :active="isLoading"
        :can-cancel="false"
        :is-full-page="false"
        background-color="#EBEDEF"
        :height="30"
        :width="30"
        color="#007bff"
      />
    </div>
  </b-modal>
</template>
<script>
import ReportService from '@/services/ReportService'
import Swal from 'sweetalert2'
import moment from '@/helpers/moment'
import _ from 'lodash'
import cAlert from '@/helpers/alert'

export default {
  name: 'ViewTicketModal',
  props: ['isShow', 'ticketId'],
  data() {
    return {
      isLoading: false,
      data: null
    }
  },
  computed: {
    title() {
      if(!this.data || this.isLoading)
        return ''

      const roundDate = moment(this.data.ticket.round.roundDate).format("DD/MM/YYYY")
      return `[${this.data.ticket.market.groupTitle}] ${this.data.ticket.market.marketTitle} - ${roundDate}`
    },
    items() {
      if(!this.data || this.isLoading)
        return []

      return _.sortBy(this.data.items, [(item)=>{
        return {
          runTop: 1,
          runBottom: 2,
          twoNumberTop: 3,
          twoNumberBottom: 4,
          threeNumberTop: 5,
          threeNumberTode: 6,
        }[item.type]
      }, (item)=>{
        return parseInt(item.number)
      }])
    },
    summary() {
      const defaultData = {
        amount: 0,
        credit: 0,
        discount: 0,
        length: 0
      }

      return {
        ...defaultData,
        ...this.data?.ticket?.summary
      }
    },
    status() {
      if(!this.data || this.isLoading)
        return ''

      return this.data.ticket.status
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.$refs['ticket-modal'].show()
      }else{
        this.$refs['ticket-modal'].hide()
      }
    }
  },
  methods: {
    loadTicket() {
      if(!this.ticketId)
        return

      this.isLoading = true
      ReportService.getTicket(this.ticketId).then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        this.$refs['ticket-modal'].hide()
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'ดึงรายการโพยไม่สำเร็จ',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close')
    })
  }
}
</script>
<style lang="scss" scoped>
.ticket-modal {
  .ticket-table {
    min-height: 100px;

    table {
      thead {
        tr {
          th {
            text-align: center;
            font-size: 90%;
            padding: 0.25rem;
          }
        }
      }
      tbody {
        tr {
          td {
            font-size: 90%;
            vertical-align: middle;
            padding: 0.25rem;
          }
        }
      }
    }
  }
}
</style>
